<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>
    <div class="section-body">
      <h2 class="section-title">{{ name }}</h2>
      <router-link :to="{ name: 'LogActivity' }" class="badge badge-info my-3"
        >Back</router-link
      >
      <div class="row">
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h4>Old Record</h4>
            </div>
            <div class="card-body">
              <div
                class="form-group"
                v-for="(attribute, index) in activityLog.old"
                :key="index"
              >
                <label>{{ index }}</label>
                <input
                  type="text"
                  class="form-control"
                  :value="attribute"
                  readonly
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h4>New Record</h4>
            </div>
            <div class="card-body">
              <div
                class="form-group"
                v-for="(attribute, index) in activityLog.attributes"
                :key="index"
              >
                <label>{{ index }}</label>
                <input
                  type="text"
                  class="form-control"
                  :value="attribute"
                  readonly
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";

export default {
  name: "LogActivity",
  data() {
    return {
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      name: "Log Activity",
      activityLog: [],
      loading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      let id = this.$route.params.id;
      axios
        .get(this.$store.state.api + "activity_log/" + id, {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then((result) => {
          this.loading = false;
          this.activityLog = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "User Account",
          value: "causer_name",
        },
        {
          text: "Amount",
          value: "nominal",
        },
      ];
    },
  },
};
</script>
