import LogActivity from './LogActivity.vue'
import LogActivityShow from './Show.vue'

export default [
    {
        path: '/logactivity',
        name: 'LogActivity',
        component: LogActivity
    },
    {
        path: '/logactivity/show/:id',
        name: 'LogActivityShow',
        component: LogActivityShow
    },
]
